// import React, { useState, useEffect, useRef } from "react";
// import styles from "./Section6.module.scss";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';

// function Section6() {
//   const videoId = "Jj3aJ_kdado";
//   const [player, setPlayer] = useState(null);
//   const [isPlaying, setIsPlaying] = useState(false);
//   const [sliderValue, setSliderValue] = useState(0);
//   const playerRef = useRef(null);

//   // Player variables to minimize YouTube branding
//   const playerVars = {
//     autoplay: 0, // Disable autoplay
//     controls: 0, // Hide controls
//     modestbranding: 1, // Minimize YouTube branding
//     rel: 0, // Do not show related videos on end
//     disablekb: 1, // Disable keyboard controls
//     fs: 0, // Hide the fullscreen button
//     iv_load_policy: 3, // Hide video annotations
//     cc_load_policy: 0, // Hide closed captions
//   };

//   useEffect(() => {
//     // Load the IFrame Player API code asynchronously
//     var tag = document.createElement("script");
//     tag.src = "https://www.youtube.com/iframe_api";
//     var firstScriptTag = document.getElementsByTagName("script")[0];
//     firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

//     // This function gets called when API is ready to use
//     window.onYouTubeIframeAPIReady = () => {
//       setPlayer(
//         new window.YT.Player(playerRef.current, {
//           videoId: videoId,
//           playerVars: playerVars,
//           events: {
//             onStateChange: onPlayerStateChange,
//           },
//         })
//       );
//     };
//   }, [videoId]);

//   useEffect(() => {
//     let interval;
//     if (player) {
//       interval = setInterval(() => {
//         if (player && player.getDuration) {
//           const currentTime = player.getCurrentTime();
//           const duration = player.getDuration();
//           const value = (currentTime / duration) * 100;
//           setSliderValue(value);
//         }
//       }, 1000); // Update every second
//     }
//     return () => {
//       if (interval) {
//         clearInterval(interval);
//       }
//     };
//   }, [player]);

//   const onPlayerStateChange = (event) => {
//     setIsPlaying(event.data === window.YT.PlayerState.PLAYING);
//     // Force custom controls to be visible when video is playing
//     if (event.data === window.YT.PlayerState.PLAYING) {
//       document.querySelector('.customControls').style.opacity = '1';
//     }
//   };
  
//   const togglePlay = () => {
//     if (!player) return;
//     if (isPlaying) {
//       player.pauseVideo();
//     } else {
//       player.playVideo();
//     }
//   };

//   const handleSliderChange = (event) => {
//     const time = player.getDuration() * (event.target.value / 100);
//     player.seekTo(time);
//     setSliderValue(event.target.value); // Update the slider value when manually changed
//   };
//   return (
//     <div className={styles.ancestorLayout + " ancestorLayout"}>
//       <div className={styles.topLayer}></div>
//       <div className={styles.bottomRight}></div>

//       <div className={styles.customControls}>
//         <button onClick={togglePlay} className={styles.playPuaseBtn}>
//           {isPlaying ? <FontAwesomeIcon icon={faPause} /> : <FontAwesomeIcon icon={faPlay} />}
//         </button>
//         <input
//           type="range"
//           min="0"
//           max="100"
//           value={sliderValue}
//           className={styles.slider}
//           onChange={handleSliderChange}
//         />
//       </div>
//       <div
//         className={styles.invisibleScrollLayer}
//         onClick={togglePlay}
//       ></div>
//       <div className={styles.videoWrapper}>
//         <div id={videoId} ref={playerRef}></div>
//       </div>
//     </div>
//   );
// }

// export default Section6;

import React from "react";
import styles from "./Section6.module.scss";
import Video from "../../../assets/video/SaikamAananda.mp4";


function Section6() {
  return (
    <div>
      <div className={styles.ancestorLayout + " ancestorLayout "}>
        {/* <div className={styles.parentLayout + " parentLayout "}> */}
          <video width="100%" height="100%" controls loop muted playsInline>
            <source src={Video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        {/* </div> */}
      </div>
    </div>
  );
}

export default Section6;
