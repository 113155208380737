import React, {useRef} from "react";
import styles from "./Section5.module.scss";
import useFadeSlideIn from "../../../hooks/useFadeSlideIn";


function Section5() {
  const titleRef = useRef(null);
  const titleAnimationStyle = useFadeSlideIn(titleRef);

  const infoBlocks = [
    { title: "LAND PARCEL", content: "45 Acres" },
    { title: "UNITS", content: "112" },
    { title: "PROJECT TYPE", content: "PLOTS" },
    { title: "CLUBHOUSE", content: "8000 Sq Ft" },
    { title: "100%", content: "Vastu Compliant" },
    { title: "DIMENSIONS", content: "30*40 SQFT | 30*50 SQFT | 30*60 SQFT" },
    // { title: "POSSESSION", content: "RTMI" },
    { title: "PHASE TWO", content: "9 ACRES" },
    { title: "RERA NO", content: "PRM / KA / RERA / 125I / 308 / PR / 290423 / 005903" },
  ];

  return (
    <div>
      <div className={`${styles.ancestorLayout} ancestorLayout`}>
        <div className={`${styles.parentLayout} parentLayout`}>
          <h1 className={styles.pageTitle}>- Project details -</h1>
          <div className={styles.gridBody}>
            {infoBlocks.map((block, index) => (
              <div key={index} className={styles.infoBlock}>
                <h3 className={styles.subTitle}>{block.title}</h3>
                <p>{block.content}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section5;
