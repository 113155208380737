import React, { useEffect, useState } from 'react';
import styles from './Home.module.scss';
import Section1 from '../../Sections/HomeSections/Section1/Section1';
import Section2 from '../../Sections/HomeSections/Section2/Section2';
import Section3 from '../../Sections/HomeSections/Section3/Section3';
import Section3P1 from '../../Sections/HomeSections/Section3P1/Section3';
import Section3P2 from '../../Sections/HomeSections/Section3P2/Section3';
import Section3P3 from '../../Sections/HomeSections/Section3P3/Section3';
import Section4 from '../../Sections/HomeSections/Section4/Section4';
import Section5 from '../../Sections/HomeSections/Section5/Section5';
import Section6 from '../../Sections/HomeSections/Section6/Section6';
import Section7 from '../../Sections/HomeSections/Section7/Section7';
import Section8 from '../../Sections/HomeSections/Section8/Section8';
import Section9 from '../../Sections/HomeSections/Section9/Section9';
import Footer from '../../Components/Footer/Footer';
import Header from '../../Components/Header/Header';
import useTrackingPixel from '../../hooks/useTrackingPixel';
function Home() {
  useTrackingPixel('17614');

  return (
    <div>
      <Header />
      <div className={styles.snapParent}>
        <div className="section fp-auto-height snap-child" id='s1'><Section1 /></div>
        <div className="section fp-auto-height snap-child" id='s2'><Section2 /></div>
        <div className="section fp-auto-height snap-child" id='s3'><Section3 /></div>
        <div className="section fp-auto-height snap-child" id='s4'><Section3P1 /></div>
        <div className="section fp-auto-height snap-child" id='s5'><Section3P2 /></div>
        <div className="section fp-auto-height snap-child" id='s6'><Section3P3 /></div>
        <div className="section fp-auto-height snap-child" id='s7'><Section4 /></div>
        <div className="section fp-auto-height snap-child" id='s8'><Section5 /></div>
        <div className="section video snap-child" id='s9'><Section6 /></div>
        <div className="section fp-auto-height snap-child" id='s10' ><Section7 /></div>
        <div className="section fp-auto-height snap-child" id='s11'><Section8 /></div>
        <div className="section fp-auto-height snap-child" id='form'><Section9 /></div>
        <div className="section footer snap-child" id='s12'><Footer /></div>
      </div>
    </div>
  );
}

export default Home;
