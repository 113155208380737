import React from "react";
import styles from "./Section9.module.scss";
import Form from "../../../Components/Form/Form";

function Section9() {
  return (
    <div >
      <Form />
    </div>
  );
}

export default Section9;
