import React, { useEffect, useRef, useState } from "react";
import styles from "./Section3.module.scss";
import img1 from "../../../assets/images/HomeSec3Img1.jpg";
import img2 from "../../../assets/images/HomeSec3Img2.jpg";
import img3 from "../../../assets/images/HomeSec3Img3.jpg";
import img4 from "../../../assets/images/HomeSec3Img4.jpg";
import useFadeSlideIn from "../../../hooks/useFadeSlideIn";

function Section3() {
  const [activeSlide, setActiveSlide] = useState(0);
  const scrollRef = useRef(null);
  const titleRef = useRef(null);
  const titleAnimationStyle = useFadeSlideIn(titleRef);


  const handleScroll = () => {
    const index = Math.round(
      scrollRef.current.scrollTop / scrollRef.current.clientHeight
    );
    setActiveSlide(index);
  };

  const pageContent = [
    // {
    //   img: img1,
    //   pageName: "Spotlights from Sai Ananda",
    //   title: "95% Trees Preserved",
    //   bodyText:
    //     "Experience the calm and freshness of Namma Bengaluru. 95% tree preservation initiative invites you to step into a world where time seems to slow down, and the chaos of the city fades into the background.",
    // },
    // {
    //   img: img2,
    //   pageName: "Spotlights from Sai Ananda",
    //   title: "95% Trees Preserved",
    //   bodyText:
    //     "Experience the calm and freshness of Namma Bengaluru. 95% tree preservation initiative invites you to step into a world where time seems to slow down, and the chaos of the city fades into the background.",
    // },
    // {
    //   img: img3,
    //   pageName: "Spotlights from Sai Ananda",
    //   title: "95% Trees Preserved",
    //   bodyText:
    //     "Experience the calm and freshness of Namma Bengaluru. 95% tree preservation initiative invites you to step into a world where time seems to slow down, and the chaos of the city fades into the background.",
    // },
    {
      img: img4,
      pageName: "Spotlights from Saikam Aananda",
      title: "Tree Houses",
      bodyText:
        "For the first time in a South Indian city, we present to you teak tree houses for short stays amidst botanical and fairy gardens. Be a part of a calm nature.",
    },
  ];
  
  
return (
    <div className={styles.ancestorLayout + " ancestorLayout "}>
              <div></div>

      <div className={styles.parentLayout + " parentLayout "}>
        <div
          className={styles.dynamicContent}
          ref={scrollRef}
          onScroll={handleScroll}
        >
          {pageContent.map((item, index) => {
            return (
              <div key={index} className={styles.contentContainer}>
              <div className={styles.imageContainer + " section3Img "}>
                  <img src={item.img} alt="image" className={styles.image + "  "} />
                </div>
                <div className={styles.textContentContainer}>
                  <p className={styles.pageName}>{item.pageName}</p>
                  <h1 className={styles.textTitle} >{item.title} </h1>
                  <p className={styles.bodyText}>{item.bodyText}</p>
                </div>
              </div>
            );
          })}
        </div>
        {/* <div className={styles.pageNumber}>
          {pageContent.map((_, index) => (
            <div
              key={index}
              className={index === activeSlide ? styles.activePage : styles.page}
            >
              {String(index + 1).padStart(2, "0")}
            </div>
          ))}
        </div> */}
      </div>
      <div></div>

    </div>
  );
}

export default Section3;