import { useState, useLayoutEffect } from 'react';

const useFadeSlideIn = (ref) => {
  const [isOnScreen, setOnScreen] = useState(false);

  useLayoutEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Trigger the animation when the element is intersecting
        setOnScreen(entry.isIntersecting);
      },
      {
        threshold: 0.1, // Consider using a threshold > 0 to ensure the element is in view
        rootMargin: '0px 0px -200px 0px' // Adjust if necessary
      }
    );

    // Check if the element is already visible and set the state immediately
    // This is important for cases where the element is already in view on initial load
    const checkIfVisible = () => {
      if (ref.current) { // Add null check
        const rect = ref.current.getBoundingClientRect();
        const isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight;
        setOnScreen(isVisible);
      }
    };

    if (ref.current) {
      checkIfVisible(); // Check visibility without waiting for scroll
      observer.observe(ref.current);
    }

    // Re-check visibility whenever the window is resized
    window.addEventListener('resize', checkIfVisible);

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
      observer.disconnect();
      window.removeEventListener('resize', checkIfVisible);
    };
  }, [ref]); // Ensure ref is a stable reference, otherwise, it could cause issues

  const animationStyle = {
    opacity: isOnScreen ? 1 : 0,
    transform: isOnScreen ? 'translateY(0)' : 'translateY(40px)',
    transition: 'opacity 1s ease-in-out, transform 1s ease-in-out',
  };

  return animationStyle;
};

export default useFadeSlideIn;
