import React, { useState, useEffect } from "react";
import styles from "./Header.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import SaikamLogo from "../../assets/logos/SaikamLogo.svg";

function Header({ alwaysVisible }) {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const pageNames = [
    "HOME",
    "EXPERIENCE",
    "PLAN",
    "SPECS",
    "AMENITIES",
    "LOCATION",
    "CONTACT US",
  ];

  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const renderPageNames = () => {
    return pageNames.map((name, index) => (
      <span
        key={index}
        className={styles.page + " ralewayFont "}
        onClick={() => {
          setDrawerOpen(false);
          switch (name) {
            case "HOME":
              scrollToSection("s1");
              break;
            case "EXPERIENCE":
              scrollToSection("s3");
              break;
            case "PLAN":
              scrollToSection("s7");
              break;
            case "SPECS":
              scrollToSection("s8");
              break;
            case "AMENITIES":
              scrollToSection("s10");
              break;
            case "LOCATION":
              scrollToSection("s11");
              break;
            case "CONTACT US":
              scrollToSection("form");
              break;
            default:
              break;
          }
        }}
      >
        {name}
      </span>
    ));
  };

  useEffect(() => {
    if (isDrawerOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
      document.body.style.zIndex = "9999";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isDrawerOpen]);

  const downloadBrochure = () => {
    setDrawerOpen(false);
    window.open("/SaikamBrochure.pdf", "_blank");
  };

  return (
    <div className={styles.ancestorLayout + " ancestorLayout "}>
      <div className={styles.parentLayout + " parentLayout "}>
        <div className={styles.logoContainer}>
          <img src={SaikamLogo} alt="icon" className={styles.SaikamLogo} />
        </div>
        <div className={styles.pageNames}>{renderPageNames()}</div>
        {/* <div className={styles.buttonContainer}>
          <button
            className={styles.brochureBtn + " cusBtn btn  ralewayFont"}
            onClick={downloadBrochure}
          >
            Brochure
          </button>
        </div> */}
        <div className={styles.iconContainer}>
          <FontAwesomeIcon
            className={styles.burgerIcon}
            icon={faBars}
            onClick={toggleDrawer}
          />
        </div>
      </div>
      {isDrawerOpen && (
        <div className={styles.drawer}>
          <FontAwesomeIcon
            icon={faTimes}
            onClick={toggleDrawer}
            className={styles.closeIcon + " icon "}
          />
          <div className={styles.drawerContent}>
            {renderPageNames()}
            <button
              className={styles.brochureBtn + " cusBtn btn ralewayFont "}
              onClick={downloadBrochure}
            >
              Brochure
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Header;
