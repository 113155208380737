import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './App.scss';
import Home from './pages/Home/Home';
import Privacy from './pages/Privacy/Privacy'; 
import Terms from './pages/Terms/Terms.jsx'; 
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import './common.scss';
import Thankyou from "./Components/thankyou/thankyou.jsx";
import Popup from './Components/popup/popup.jsx';
import Cookie from "./pages/Cookie Policy/Cookie-policy.jsx"

function AppContent() {
  const location = useLocation();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isEnquire, setIsEnquire] = useState(false);

  const handleBrochureClick = () => {
    setIsPopupOpen(true);
    setIsEnquire(false);
  };

  const handleEnquireClick = () => {
    setIsPopupOpen(true);
    setIsEnquire(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const handleFormSuccess = () => {
    closePopup();
    if (!isEnquire) {
      window.open("/SaikamBrochure.pdf", "_blank"); // Trigger PDF download only if it's not an enquiry
    }
  };

  return (
    <div className="App">
      <div className="cus-side-btns">
        <button className='cus-brochure-btn' onClick={handleBrochureClick}>Download Brochure</button>
        <button className='cus-enquire-btn' onClick={handleEnquireClick}>Enquire Now</button>
      </div>
      <Popup isOpen={isPopupOpen} onClose={closePopup} onFormSuccess={handleFormSuccess} />
      <Routes>
        <Route path="/" element={
          <>
            <Helmet>
              <title>Villa & Villa Plots in Sarjapur, Bangalore | Saikam Aananda</title>
              <meta name="description" content="Buy a villa plot in one of the biggest plotted development projects in Bangalore. Request callback." />
            </Helmet>
            <Home />
          </>
        } />
        <Route path="/privacy-policy" element={
          <>
            <Helmet>
              <title>Saikamaananda</title>
              <meta name="description" content="Privacy Policy | Saikam Aananda" />
            </Helmet>
            <Privacy />
          </>
        } />
        <Route path="/terms-of-use" element={
          <>
            <Helmet>
              <title>Saikamaananda</title>
              <meta name="description" content="Terms of Use | Saikam Aananda" />
            </Helmet>
            <Terms />
          </>
        } />
        <Route path="/thankyou" element={
          <>
            <Helmet>
              <title>Saikamaananda</title>
              <meta name="description" content="Thank you" />
            </Helmet>
            <Thankyou />
          </>
        } />
        <Route path="/cookie-policy" element={
          <>
            <Helmet>
              <title>Cookie Policy - Saikamaananda</title>
              <meta name="description" content="Cookie Policy" />
            </Helmet>
            <Cookie />
          </>
        } />
      </Routes>
    </div>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
