import React, { useState } from "react";
import styles from "./Footer.module.scss";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookSquare, faInstagram } from "@fortawesome/free-brands-svg-icons";
import footerLogo from "../../assets/logos/footerLogo.svg";
function Footer({
  section1Ref,
  section2Ref,
  section3Ref,
  section4Ref,
  section5Ref,
  section6Ref,
  section7Ref,
  section8Ref,
  section9Ref,
}) {
  const scrollToHome = () => {
    section1Ref.current.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToPlans = () => {
    section4Ref.current.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToLocation = () => {
    section8Ref.current.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToContact = () => {
    section9Ref.current.scrollIntoView({ behavior: "smooth" });
  };
  const [currentSection, setCurrentSection] = useState(null);
  const scrollToSection = (sectionNumber) => {
    window.fullpage_api.moveTo(sectionNumber);
  };

  return (
    <div className={styles.ancestorLayout + " ancestorLayout "}>
      <div className={styles.parentLayout + " parentLayout "}>
        {/* <div className={styles.pagesContainer}>
          <span className={styles.home} onClick={scrollToHome}>Home</span>
          <span className={styles.plans} onClick={scrollToPlans}>Plans</span>
          <span className={styles.location} onClick={scrollToLocation}>Location</span>
          <span className={styles.contactUs} onClick={scrollToContact}>Contact Us</span>
          <span className={`${styles.privacyPolicy} privacyPolicy`}>
            Privacy Policy
          </span>
          <span className={`${styles.termsOfServices} termsOfServices`}>
            Terms of Services
          </span>
          <span className={`${styles.contactNo} contactNo`}>
            CONTACT NO: +91 81475 70789
          </span>
          <div className={`${styles.socialIconContainer} socialIconContainer`}>
            <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFacebookSquare} />
            </a>
            <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          </div>
        </div>
        
      </div>
      <div className={styles.bottom}>
          <a href="https://credvest.com/#/" target="blank" className={styles.poweredBy}>Powered by Credvest India Private Limited</a>
            <p className={styles.codeNameCrown}>Saikam Aananda</p> */}
        <div className={styles.gridContainer}>
          <div className={styles.imgContainer}>
            <img
              src={footerLogo}
              alt="image"
              className={styles.footerSaikamLogo}
            />
          </div>
          <div className={styles.infoGroup}>
            <p className={styles.label + " ralewayFont "}>INFO</p>
            <h5
              className={styles.title + " " + styles.formText}
              onClick={() => scrollToSection(12)}
            >
              Form
            </h5>
            {/* <p className={styles.bodyText + " ralewayFont "}>About Us</p>
            <p className={styles.bodyText  + " ralewayFont "}>Contacts</p> */}
          </div>
          <div className={styles.contactUsGroup}>
            <p className={styles.label}>Contact us</p>
            <h5 className={styles.title + " archiaFont "}>+91 81475 70789</h5>
          </div>
          <div className={styles.findUsGroup}>
            <p className={styles.label}>Find us</p>
            <h5 className={styles.title + " archiaFont "}>
              Sy No. 70, Chikkadasarahalli Village, Sarjapura, Bangalore 562 125
            </h5>
          </div>
        </div>
        <div className={styles.bottom}>
          <div className={styles.bottomLeft}>© 2023 — Copyright</div>
          <div className={styles.credvest}></div>
          <div className={styles.bottomRight}>
            <a
              href="/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.privacy}
            >
              Privacy Policy
            </a>
            <a
              href="/terms-of-use"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.terms}
            >
              Terms of Use
            </a>
            <a
              href="/cookie-policy"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.cookie}
            >
              Cookie Policy
            </a>
          </div>
        </div>
        <div className={styles.end}>
          <a
            href="https://www.credvest.com/"
            target="_blank"
            className={styles.power + " ralewayFont "}
          >
            POWERED BY CREDVEST INDIA PRIVATE LIMITED
          </a>
          <a
            href="https://www.saikamaananadaofficial.com/"
            target="_blank"
            className={styles.saikam + " ralewayFont "}
          >
            {" "}
            SAIKAM AANANDA{" "}
          </a>
          <div className={styles.socials}>
          <a
            href="https://www.facebook.com/profile.php?id=61550475485779"
            target="_blank"
            className={styles.fb + " ralewayFont "}
          >
              <FontAwesomeIcon icon={faFacebookSquare} className="fa-fb"/>
          </a>
          <a
            href="https://www.instagram.com/saikam_aananda/"
            target="_blank"
            className={styles.ig + " ralewayFont "}
          >
              <FontAwesomeIcon icon={faInstagram} className="fa-ig"/>
          </a>
          </div>
        </div>
      </div>
      <></>
    </div>
  );
}

export default Footer;
