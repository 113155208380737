import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./thankyou.module.scss"
import useTrackingPixel from "../../hooks/useTrackingPixel";

function Thankyou() {
    useTrackingPixel('17613');
    const navigate = useNavigate();
    const navigateToForm = () => {
        navigate("/", { state: { fromThankyou: true, scrollToForm: true } });
    }
    
  return (
    <div className={styles.thankyouRoot}>
      <div className={styles.alignment}>
          <h3>
          Thank you for submitting your form. It has been successfully received. Our
              team will get back to you shortly.
          </h3>
          <button className={styles.backBtn} onClick={navigateToForm} > Back</button>
      </div>
    </div>
  );
}

export default Thankyou;
