import React, { useRef } from "react";
import styles from "./Section2.module.scss";
import useFadeSlideIn from "../../../hooks/useFadeSlideIn";

function Section2() {
  const titleRef = useRef(null);
  const titleAnimationStyle = useFadeSlideIn(titleRef);

  return (
    <div className={styles.ancestorLayout + " ancestorLayout "}>
      <div className={styles.parentLayout + " parentLayout "}>
        <h1 className={styles.textTitle + " textTitle "}>Plot Your Future </h1>
        <p className={styles.textBody + " textBody "}>
          {" "}
          Saikam Aananda, a BMRDA and RERA-approved plotted development, right
          at the heart of Sarjapur. Discover our close-knit community, offering
          villa customization options. Enjoy 52% open spaces, landscaped
          gardens, a children's area, and an 8000 clubhouse. Sarjapur is the prime
          choice for plot appreciation, owing to its exceptional social
          infrastructure. Just as our forefathers entrusted us with their
          valuable inheritance, now you can pave the way for your future
          generations.
        </p>
      </div>
    </div>
  );
}

export default Section2;
