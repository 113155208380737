import React, {useRef} from "react";
import styles from "./Section4.module.scss";
import img from "../../../assets/images/homeSec4Img.jpg";
import useFadeSlideIn from "../../../hooks/useFadeSlideIn";


function Section4() {
  const titleRef = useRef(null);
  const titleAnimationStyle = useFadeSlideIn(titleRef);

  return (
    <div className={styles.ancestorLayout + " ancestorLayout "}>
      <div className={styles.bgBlock}></div>
      <div className={styles.parentLayout + " parentLayout "}>
        <div className={styles.textPane}>
          <h1 className={styles.heading}>MASTER PLAN</h1>
          <p className={styles.description}>
            Opt from what suits you the best. We have distinguished sizes which
            go like 30*40, 30*50, 30*60. <br />We aim to provide you with a home that
            perfectly suits your needs and aspirations.
          </p>
        </div>
        <div className={styles.imagePanel + " section4Img "}>
          <img src={img} alt="Map Image" className={styles.mapImage} />
        </div>
      </div>
    </div>
  );
}

export default Section4;
