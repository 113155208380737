import React, { useState, useRef, useEffect } from 'react';
import styles from "./Section8.module.scss";
import Map from "../../../assets/images/map.png";
import useMagnify from '../../../hooks/useMagnify';
import Expandable from "../../../Components/Expandable/Expandable";
import useFadeSlideIn from "../../../hooks/useFadeSlideIn";


function Section8() {
  const titleRef = useRef(null);
  const titleAnimationStyle = useFadeSlideIn(titleRef);
  const [zoomLevel, setZoomLevel] = useState(window.innerWidth < 768 ? 3 : 2);
  // Effect to update the zoom level on window resize
  useEffect(() => {
    const handleResize = () => {
      setZoomLevel(window.innerWidth < 768 ? 3 : 2);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const {
    imageRef,
    showLens,
    lensStyle,
    zoomedImageStyle,
    handleMouseEnter,
    handleMouseLeave,
    handleMouseMove,
    handleTouchMove,
    handleTouchStart,
    handleTouchEnd,
  } = useMagnify(zoomLevel); // Pass the dynamic zoom level here

  const expandableContents1 = [

    {
      title: "SCHOOLS",
      body: [
        { name: "Oakridge International", time: "14 mins" },
        { name: "Greenwood High School", time: "17 mins" },
      ],
    },
    {
      title: "TECH PARK",
      body: [
        { name: "RGA Tech Park", time: "26 mins" },
        { name: "Wipro Technology Campus", time: "29 mins" },
      ],
    },
    {
      title: "HOSPITALS",
      body: [
        { name: "Sahasra Hospitals", time: "24 mins" },
        { name: "Spandana Heart & Super Speciality Hospital", time: "14 mins" },
      ],
    },

  ];
  const expandableContents2 = [
    {
      title: "MARKETS",
      body: [
        { name: "D-Mart", time: "13 mins" },
        { name: "Amity Mart", time: "12 mins" },
      ],
    },
    {
      title: "SHOPPING MALLS",
      body: [
        { name: "Nexus Whitefield", time: "36 mins" },
        { name: "Pheonix Marketcity", time: "46 mins" },
      ],
    },
  ];

  return (
    <div className={styles.ancestorLayout + " ancestorLayout "}>
      <div className={styles.parentLayout + " parentLayout "}>
        <div className={styles.exGroup}>
          <div className={styles.expandable}>
            <Expandable
              sections={expandableContents1}
              titleAndIcon={styles.ExTitleAndIcon}
              title={styles.ExTitle + " ralewayFont"}
              body={styles.ExBody}
              bodyContainer={styles.ExBodyContainer}
              icon={styles.ExIcon}
              iconContainer={styles.ExIconContainer}
              rootContainer={styles.ExRootContainer}
              nameTime={styles.ExNameTime}
              bodyName={styles.ExBodyName}
              time={styles.ExTime}
            />
          
          </div>
          <div className={styles.expandable}>
            <Expandable
              sections={expandableContents2}
              titleAndIcon={styles.ExTitleAndIcon}
              title={styles.ExTitle + " ralewayFont"}
              body={styles.ExBody}
              bodyContainer={styles.ExBodyContainer}
              icon={styles.ExIcon}
              iconContainer={styles.ExIconContainer}
              rootContainer={styles.ExRootContainer}
              nameTime={styles.ExNameTime}

            />
          
          </div>
        </div>
        <div className={styles.mapContainer}
           onMouseEnter={handleMouseEnter}
           onMouseLeave={handleMouseLeave}
           onMouseMove={handleMouseMove}
           onTouchStart={handleTouchStart} // Add this line to handle touch start
           onTouchMove={handleTouchMove} // Add this line to handle touch move
           onTouchEnd={handleTouchEnd} // Add this line to handle touch end
        >
          <img ref={imageRef} src={Map} alt="Map Image" className={styles.map} />
          {showLens && (
            <div
              className={styles.magnifierLens}
              style={lensStyle} // Apply the lensStyle here
            >
              <img
                src={Map}
                alt="Zoomed Map Image"
                style={zoomedImageStyle} // Apply the zoomedImageStyle here
              />
            </div>
          )}
        </div>
        <h5 className={styles.pageTitle}>- LOCATION -</h5>

      </div>
    </div>
  );
}

export default Section8;
