import { useState, useRef, useEffect } from 'react';

const useMagnify = (zoomLevel = 2) => {
  const [showLens, setShowLens] = useState(false);
  const [lensPosition, setLensPosition] = useState({ x: 0, y: 0 });
  const [zoomedImagePosition, setZoomedImagePosition] = useState({ x: 0, y: 0 });
  const imageRef = useRef(null);

  // Update lens size based on screen width dynamically
  const [lensSize, setLensSize] = useState(window.innerWidth < 768 ? 150 : 200);

  useEffect(() => {
    const updateLensSize = () => {
      setLensSize(window.innerWidth < 768 ? 150 : 200);
    };

    window.addEventListener('resize', updateLensSize);
    return () => window.removeEventListener('resize', updateLensSize);
  }, []);

  const calculatePosition = (pageX, pageY) => {
    if (!imageRef.current) return;
    const { top, left, width, height } = imageRef.current.getBoundingClientRect();
    const x = pageX - left - window.pageXOffset;
    const y = pageY - top - window.pageYOffset;

    // Calculate the lens position to be centered on the cursor
    const lensX = Math.max(0, Math.min(x - lensSize / 2, width - lensSize));
    const lensY = Math.max(0, Math.min(y - lensSize / 2, height - lensSize));

    // Calculate the zoomed image's position
    const zoomedX = -lensX * zoomLevel;
    const zoomedY = -lensY * zoomLevel;

    setLensPosition({ x: lensX, y: lensY });
    setZoomedImagePosition({ x: zoomedX, y: zoomedY });
  };

  const handleMouseMove = (e) => {
    if (!imageRef.current) return;
    calculatePosition(e.pageX, e.pageY);
  };

  const handleTouchMove = (e) => {
    e.preventDefault(); // Prevent scrolling when touching the image
    if (!imageRef.current) return;
    const touch = e.touches[0];
    const touchX = touch.clientX;
    const touchY = touch.clientY;
    calculatePosition(touchX, touchY);
  };

  const handleMouseEnter = () => setShowLens(true);
  const handleMouseLeave = () => setShowLens(false);

  const handleTouchStart = (e) => {
    e.preventDefault();
    setShowLens(true);
    const touch = e.touches[0];
    const touchX = touch.clientX;
    const touchY = touch.clientY;
    calculatePosition(touchX, touchY);
  };

  const handleTouchEnd = () => setShowLens(false);

  // Styles for the magnifying lens
  const lensStyle = {
    position: 'absolute',
    border: '1px solid #000',
    cursor: 'none',
    width: `${lensSize}px`, // Dynamic size of the lens
    height: `${lensSize}px`,
    overflow: 'hidden',
    visibility: showLens ? 'visible' : 'hidden',
    opacity: showLens ? 1 : 0,
    transition: 'visibility 0.2s, opacity 0.2s ease-in-out',
    left: `${lensPosition.x}px`,
    top: `${lensPosition.y}px`,
  };

  // Styles for the zoomed image
  const zoomedImageStyle = {
    position: 'absolute',
    left: `${zoomedImagePosition.x}px`,
    top: `${zoomedImagePosition.y}px`,
    width: `${imageRef.current ? imageRef.current.offsetWidth * zoomLevel : 0}px`,
    height: `${imageRef.current ? imageRef.current.offsetHeight * zoomLevel : 0}px`,
  };

  return {
    imageRef,
    showLens,
    lensStyle,
    zoomedImageStyle,
    handleMouseEnter,
    handleMouseLeave,
    handleMouseMove,
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
  };
};

export default useMagnify;
