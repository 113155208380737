import React, {useRef} from "react";
import styles from "./Section1.module.scss";
import BgImg from "../../../assets/images/HomeSec1Bg.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faFacebook } from "@fortawesome/free-brands-svg-icons";
import useFadeSlideIn from "../../../hooks/useFadeSlideIn";

function Section1() {
  const titleRef = useRef(null);
  const titleAnimationStyle = useFadeSlideIn(titleRef);


  return (
    <div className={styles.ancestorLayout + " ancestorLayout "}>
      <div className={styles.BgImgContainer}>
        <img src={BgImg} alt="Image" className={styles.BgImg} />
      </div>
      <div className={styles.parentLayout + " parentLayout "}>
        {/* <div className={styles.socialIcons}>
          <a
            href="https://www.facebook.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faInstagram} className={styles.icon} />
          </a>
          <a
            href="https://www.instagram.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faFacebook} className={styles.icon} />
          </a>
        </div> */}
        <div className={styles.heroText + " heroText "}>
        A Larger than Life <br />
          <span className={styles.futureText}>Plotted Community </span>
        </div>
        <p className={styles.subHero}>45 Acres | Multi Phase | 52% Open spaces | 8K sq ft Clubhouse </p>
      </div>
    </div>
  );
}

export default Section1;
