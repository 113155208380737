import React, {useEffect, useRef} from "react";
import styles from "./Section7.module.scss";
//icons
import img1 from "../../../assets/icons/1.svg";
import img2 from "../../../assets/icons/2.svg";
import img3 from "../../../assets/icons/3.svg";
import img4 from "../../../assets/icons/4.svg";
import img5 from "../../../assets/icons/5.svg";
import img6 from "../../../assets/icons/6.svg";
import img7 from "../../../assets/icons/7.svg";
import img8 from "../../../assets/icons/8.svg";
import img9 from "../../../assets/icons/9.svg";
import img10 from "../../../assets/icons/10.svg";
import img11 from "../../../assets/icons/11.svg";
import img12 from "../../../assets/icons/12.svg";
import img13 from "../../../assets/icons/13.svg";
import img14 from "../../../assets/icons/14.svg";
import img15 from "../../../assets/icons/15.svg";
import img16 from "../../../assets/icons/16.svg";
import img17 from "../../../assets/icons/17.svg";
import img18 from "../../../assets/icons/18.svg";
import img19 from "../../../assets/icons/19.svg";
import img20 from "../../../assets/icons/20.svg";
import img21 from "../../../assets/icons/21.svg";
// import img22 from "../../../assets/icons/22.svg";
//font awesome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import useFadeSlideIn from "../../../hooks/useFadeSlideIn";


import Carousel from "../../../Components/Carousel/Carousel";
function Section7() {
  const titleRef = useRef(null);
  const titleAnimationStyle = useFadeSlideIn(titleRef);

  const iconContainerRef = useRef(null);

  const cardFirstData = [
    { src: img1, alt: "Image", iconText: "Treehouse" },
    { src: img2, alt: "Image", iconText: "Swimming Pool" },
    { src: img3, alt: "Image", iconText: "Waterfront Jogging Park" },
    { src: img4, alt: "Image", iconText: "Gazebo" },
    { src: img5, alt: "Image", iconText: "Basketball" },
    { src: img6, alt: "Image", iconText: "Pet Park" },
    { src: img7, alt: "Image", iconText: "AQUA PARK" },
    { src: img8, alt: "Image", iconText: "LANDSCAPE SEATING AREA" },

  ];
  const cardSecondData = [
    { src: img9, alt: "Image", iconText: "AMPHITHEATRE" },
    { src: img10, alt: "Image", iconText: "BARBEQUE STATION" },
    { src: img11, alt: "Image", iconText: "EUROPEAN STYLE WATERFRONT BRIDGE" },
    { src: img12, alt: "Image", iconText: "KIDS PARK" },
    { src: img13, alt: "Image", iconText: "BEACH VOLLEY BALL" },
    { src: img14, alt: "Image", iconText: "BADMINTON COURT" },
    { src: img15, alt: "Image", iconText: "LIBRARY" },
    { src: img16, alt: "Image", iconText: "OUTDOOR GYM" },
  ];
  const cardThirdData = [
    { src: img17, alt: "Image", iconText: "KABADDI COURT" },
    { src: img18, alt: "Image", iconText: "HOPSCOTCH" },
    { src: img19, alt: "Image", iconText: "KHO-KHO" },
    { src: img20, alt: "Image", iconText: "TRADITIONAL EXERCISE" },
    { src: img21, alt: "Image", iconText: "YOGA AREA" },

  ];
  const x = (
    <div className={styles.iconsDiv}>
      {cardFirstData.map((icon, index) => (
        <div key={index} className={styles.iconTextDiv}>
          <div className={styles.iconImgContainer}>
            <img src={icon.src} alt={icon.alt} className={styles.icon}></img>
          </div>
          <p className={styles.iconText}>{icon.iconText}</p>
        </div>
      ))}
    </div>
  );
  const y = (
    <div className={styles.iconsDiv}>
      {cardSecondData.map((icon, index) => (
        <div key={index} className={styles.iconTextDiv}>
          <div className={styles.iconImgContainer}>
            <img src={icon.src} alt={icon.alt} className={styles.icon}></img>
          </div>
          <p className={styles.iconText}>{icon.iconText}</p>
        </div>
      ))}
    </div>
  );
  const z = (
    <div className={styles.iconsDiv}>
      {cardThirdData.map((icon, index) => (
        <div key={index} className={styles.iconTextDiv}>
          <div className={styles.iconImgContainer}>
            <img src={icon.src} alt={icon.alt} className={styles.icon}></img>
          </div>
          <p className={styles.iconText}>{icon.iconText}</p>
        </div>
      ))}
    </div>
  );
  // useEffect(() => {
  //   const animations = ["rotate", "scaleUp", "wiggle", "bounce", "fadeInOut", "swing", "pulse", " floatAnimation "];
    
  //   if (iconContainerRef.current) {
  //     const icons = iconContainerRef.current.querySelectorAll(`.${styles.icon}`);
  //     icons.forEach(icon => {
  //       const randomAnimation = animations[Math.floor(Math.random() * animations.length)];
  //       const randomDuration = Math.random() * 5 + 5; // Random duration between 5s to 10s
  //       const randomDelay = Math.random() * 5; // Random delay between 0s to 5s
  //       icon.style.animation = `${randomAnimation} ${randomDuration}s infinite ${randomDelay}s`;
  //     });
  //   }
  // }, []);
  
  
  
  return (
    <div className={styles.ancestorLayout + " ancestorLayout "} ref={iconContainerRef}>
      <div className={styles.parentLayout + " parentLayout "}>
        <h5 className={styles.pageTitle}>- AMENITIES -</h5>
        <div className={styles.carousel}>
          <Carousel items={[x, y, z]} showPageNumber={true}/>
        </div>
      </div>
    </div>
  );
}

export default Section7;
