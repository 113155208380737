import { useEffect } from "react";

const useTrackingPixel = (pixelId) => {
  useEffect(() => {
    console.log(`useTrackingPixel hook initiated for ID: ${pixelId}`);

    const existingScript = document.querySelector(`script[data-pixel-id="${pixelId}"]`);
    if (existingScript) {
      console.log(`Tracking pixel script with ID: ${pixelId} already exists. Skipping creation.`);
      return;
    }

    console.log(`Creating tracking pixel script for ID: ${pixelId}`);
    const script = document.createElement("script");
    script.setAttribute("data-pixel-id", pixelId);
    script.text = `!function(px){
      function e(t,e,c){
        var n="",r="";
        try{
          ""!=(n=function(t,e){
            try{
              var c={};
              e.replace(/[?&]+([^=&]+)=([^&]*)/gi,function(t,e,n){
                c[e]=n
              });
              return c.hasOwnProperty(t)?c[t]:""
            }catch(t){
              return""
            }
          }(t,c))?function(t,e,c){
            try{
              var n,r;
              c?((n=new Date).setTime(n.getTime()+864e5),r="; expires="+n.toGMTString()):r="";
              document.cookie=t+"="+e+r+";Path=/"
            }catch(t){}
          }(e,n,1):n=function(t){
            try{
              var e=document.cookie.match(new RegExp("(^| )"+t+"=([^;]+)"));
              if(e) return e[2]
            }catch(t){}
            return null
          }(e),r=""!=n&&null!=n?"&"+t+"="+n:"&"+t+"="
        }catch(t){}
        return r
      }
      var c="",n="",r="";
      try{
        n=e("ad","acf",c=window.location.href),
        r=e("col_ci","col_ci",c)
      }catch(t){
        console.log(t)
      }
      var a="https://ade.clmbtech.com/cde/eventTracking.htm?pixelId="+px+"&_w=1&_t=2"+n+r+"&rd="+(new Date).getTime();
      console.log('Tracking URL:', a); // Log the URL
      (new Image).src=a
    }('${pixelId}');
    `;
    document.body.appendChild(script);

    return () => {
      console.log(`Removing tracking pixel script for ID: ${pixelId}`);
      const scriptToRemove = document.querySelector(`script[data-pixel-id="${pixelId}"]`);
      if (scriptToRemove) {
        document.body.removeChild(scriptToRemove);
      }
    };
  }, [pixelId]);

  console.log(`useTrackingPixel hook mounted for ID: ${pixelId}`);
};

export default useTrackingPixel;
