import React from "react";
import styles from "./Privacy.module.scss";

function Privacy() {
  return (
    <div className={styles.ancestorLayout + " ancestorLayout "}>
      <div className={styles.parentLayout + " parentLayout pt-5"}>
        <h1>Privacy Policy</h1>
        <p>
          At Saikam Aananda, we respect your personal data. This Privacy Policy
          explains how we collect, process, and store your personal data. Please
          read this policy carefully.
        </p>
        <p>
          The term 'Saikam Aananda' or 'us' or 'we' refers to the owner of the
          website. The term 'you' refers to the user or viewer of our website.
        </p>
        <p>
          By using our website, you consent to the data practices described in
          this statement.
        </p>
        <h2>Collection of your Personal Information</h2>
        <p>
          We collect personally identifiable information, such as your email
          address, name, home or work address, or telephone number. We may also
          collect anonymous demographic information, which is not unique to you,
          such as your postal code, age, gender, preferences, interests, and
          favorites.
        </p>
        <h2>Use of your Personal Information</h2>
        <p>
          Saikam Aananda collects and uses your personal information to operate
          the Saikam Aananda website and deliver the services you have
          requested. We may also use your personally identifiable information to
          inform you of other products or services available from Saikam Aananda
          and its affiliates.
        </p>
        <h2>Security of your Personal Information</h2>
        <p>
          Saikam Aananda secures your personal information from unauthorized
          access, use, or disclosure. We secure the personally identifiable
          information you provide on computer servers in a controlled, secure
          environment, protected from unauthorized access, use, or disclosure.
        </p>
        <h2>Changes to this Statement</h2>
        <p>
          Saikam Aananda will occasionally update this Privacy Policy to reflect
          company and customer feedback. We encourage you to periodically review
          this Policy to be informed of how Saikam Aananda is protecting your
          information.
        </p>
        <h2>Contact Information</h2>
        <p>
          Saikam Aananda welcomes your comments regarding this Privacy Policy.
          If you believe that Saikam Aananda has not adhered to this Policy,
          please contact us at crm@saikam.com. We will use commercially
          reasonable efforts to promptly determine and remedy the problem.
        </p>
      </div>
    </div>
  );
}

export default Privacy;
