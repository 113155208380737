import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";

const Expandable = ({
  sections,
  title,
  titleAndIcon,
  iconContainer,
  icon,
  body,
  rootContainer,
  nameTime,
  bodyName,
  time,
}) => {
  const [expandedSection, setExpandedSection] = useState(null);
  const contentRefs = useRef(sections.map(() => React.createRef()));

  useEffect(() => {
    sections.forEach((section, index) => {
      if (section.title === expandedSection && contentRefs.current[index].current) {
        contentRefs.current[index].current.style.maxHeight = `${contentRefs.current[index].current.scrollHeight}px`;
      } else if (contentRefs.current[index].current) {
        contentRefs.current[index].current.style.maxHeight = '0px';
      }
    });
  }, [expandedSection, sections]);

  const toggleSection = (sectionTitle) => {
    setExpandedSection(expandedSection === sectionTitle ? null : sectionTitle);
  };

  return (
    <div className={rootContainer}>
      {sections.map((section, index) => (
        <div key={index}>
          <div className={titleAndIcon} onClick={() => toggleSection(section.title)}>
            <span className={title}>{section.title}</span>
            <span className={iconContainer}>
              <FontAwesomeIcon
                icon={expandedSection === section.title ? faMinus : faPlus}
                className={icon}
              />
            </span>
          </div>
          <div
            style={{
              overflow: 'hidden',
              maxHeight: '0px',
              transition: 'max-height 0.4s ease-out',
            }}
            ref={contentRefs.current[index]}
          >
            <div className={body}>
              {/* Map through each item in the section's body array */}
              {section.body.map((item, itemIndex) => (
                <div key={itemIndex} className={nameTime}>
                  <span className={bodyName}>{item.name}</span>  <span className={time}>{item.time}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Expandable;
