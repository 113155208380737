import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";

const Carousel = ({ items, showPageNumber = false }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [touchStartX, setTouchStartX] = useState(null);
  const [touchEndX, setTouchEndX] = useState(null);

  const goNext = () => {
    if (currentIndex < items.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const goPrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleTouchStart = (e) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (touchStartX - touchEndX > 100) {
      // Swipe left
      goNext();
    }
    if (touchEndX - touchStartX > 100) {
      // Swipe right
      goPrev();
    }
  };

  return (
    <>
    <style>
      {`
        .cusPreviousBtn {
          position: absolute;
          left: 0;
          top: 50%;
          z-index: 1;
          background-color: transparent;
          border: none;
          font-size: 36px !important;
          color: white;
        }
        .cusNextBtn {
          position: absolute;
          right: 0;
          top: 50%;
          z-index: 1;
          background-color: transparent;
          border: none;
          color: white;
          font-size: 36px !important;
        }
        .carouselParent {
          overflow-x: clip;
        }
        @media (max-width: 500px) {
          .cusPreviousBtn {
            font-size: 24px !important;
            left: 14px;
          }
          .cusNextBtn {
            font-size: 24px !important;
            right: 14px;
          }
        }
        `}
    </style>
      <div
        style={{ overflowX: "hidden", overflowX: "clip" }}
        onTouchStart={handleTouchStart}
        onTouchMove={(e) => setTouchEndX(e.touches[0].clientX)}
        onTouchEnd={handleTouchEnd}
      >
        {currentIndex > 0 && (
          <button
            onClick={goPrev}
            style={{

            }}
            className={"cusPreviousBtn"}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </button>
        )}
        <div
          style={{
            transform: `translateX(-${currentIndex * 100}%)`,
            transition: "transform 0.5s ease-in-out",
            display: "flex",
          }}
        >
          {items.map((item, index) => (
            <div key={index} style={{ flex: "0 0 100%" }}>
              {item}
            </div>
          ))}
        </div>
        {currentIndex < items.length - 1 && (
          <button
            onClick={goNext}
            style={{

      
            }}
            className={" cusNextBtn "}
          >
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
        )}
        {showPageNumber && (
          <div
            style={{
              position: "relative",
              zIndex: "2",
              color: "white",
              fontSize: "18px",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              marginTop: "20px",
            }}
            className="poppins"
          >
            <span
            style={{
              fontSize: "14px",
            }}
            >{`- ${(currentIndex + 1).toString().padStart(2, "0")}/`}</span>
            <span style={{
              fontSize: "14px",
              marginLeft: "2px",
              color: "rgba(255, 255, 255, 0.493)",
            }}>
              {`${items.length.toString().padStart(2, "0")}`}</span>
          </div>
        )}
      </div>
    </>
  );
};

export default Carousel;
