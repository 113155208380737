import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Form.module.scss";
import emailjs from "emailjs-com";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare, faCheckSquare } from "@fortawesome/free-regular-svg-icons";

function Form({ onSuccess }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [interests, setInterests] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showInterestError, setShowInterestError] = useState(false);
  const navigate = useNavigate();

  const navigateToThankYou = () => {
    navigate("/thankyou");
  };

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    if (event.target.checked) {
      setInterests([...interests, value]);
    } else {
      setInterests(interests.filter((item) => item !== value));
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (interests.length === 0) {
      setShowInterestError(true);
      return;
    }
    setShowInterestError(false);

    setIsLoading(true);

    const formData = {
      name,
      email,
      phone,
      interests: interests.join(", "),
    };

    const serviceId = "service_zfmxvya";
    const templateId = "template_686ec1b";
    const userId = "6-QdLjlzl5V_YnnGJ";

    emailjs
      .send(serviceId, templateId, formData, userId)
      .then((result) => {
        setIsLoading(false);
        navigateToThankYou();
        setName("");
        setEmail("");
        setPhone("");
        setInterests([]);
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error:", error);
        alert(
          "An error occurred while submitting the form. Please try again later."
        );
      });
  };

  return (
    <div className={styles.ancestorLayout + " ancestorLayout "}>
      <div className={styles.parentLayout + " parentLayout "}>
        <div className={styles.pageTitle}>
          <h1>- FORM -</h1>
        </div>
        <form onSubmit={handleFormSubmit} className={styles.form}>
          <div className={styles.inputGroup}>
            <label className={styles.inpLabel + " ralewayFont "}>NAME*</label>
            <input
              type="text"
              className={styles.input}
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className={styles.inputGroup}>
            <label className={styles.inpLabel + " ralewayFont "}>EMAIL*</label>
            <input
              type="email"
              className={styles.input}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className={styles.inputGroup}>
            <label className={styles.inpLabel + " ralewayFont "}>
              MOBILE NO.*
            </label>
            <input
              type="tel"
              className={styles.input}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
          </div>
          <div className={styles.inputGroup}>
            <label className={styles.checkBoxMainLabel}>
              I'M INTERESTED IN*
            </label>
            <div className={styles.checkBoxSection}>
              <div className={styles.checkBoxGroup + " ralewayFont "}>
                <label htmlFor="option1" className={styles.checkboxLabel}>
                  <input
                    type="checkbox"
                    id="option1"
                    value="1800"
                    className={styles.checkboxInput}
                    onChange={handleCheckboxChange}
                    checked={interests.includes("1800")}
                  />
                  <FontAwesomeIcon
                    icon={
                      interests.includes("1800") ? faCheckSquare : faSquare
                    }
                    className={styles.aweBox}
                  />
                  <span>1800</span>
                </label>
              </div>
              <div className={styles.checkBoxGroup + " ralewayFont "}>
                <label htmlFor="option2" className={styles.checkboxLabel}>
                  <input
                    type="checkbox"
                    id="option2"
                    value="2000"
                    className={styles.checkboxInput}
                    onChange={handleCheckboxChange}
                    checked={interests.includes("2000")}
                  />
                  <FontAwesomeIcon
                    icon={
                      interests.includes("2000") ? faCheckSquare : faSquare
                    }
                    className={styles.aweBox}
                  />
                  <span>2000</span>
                </label>
              </div>
              <div className={styles.checkBoxGroup + " ralewayFont "}>
                <label htmlFor="option3" className={styles.checkboxLabel}>
                  <input
                    type="checkbox"
                    id="option3"
                    value="2400"
                    className={styles.checkboxInput}
                    onChange={handleCheckboxChange}
                    checked={interests.includes("2400")}
                  />
                  <FontAwesomeIcon
                    icon={
                      interests.includes("2400") ? faCheckSquare : faSquare
                    }
                    className={styles.aweBox}
                  />
                  <span>2400</span>
                </label>
              </div>
              <div className={styles.checkBoxGroup + " ralewayFont "}>
                <label htmlFor="option4" className={styles.checkboxLabel}>
                  <input
                    type="checkbox"
                    id="option4"
                    value="2700"
                    className={styles.checkboxInput}
                    onChange={handleCheckboxChange}
                    checked={interests.includes("2700")}
                  />
                  <FontAwesomeIcon
                    icon={
                      interests.includes("2700") ? faCheckSquare : faSquare
                    }
                    className={styles.aweBox}
                  />
                  <span>2700</span>
                </label>
              </div>
              {showInterestError && (
                <p className={styles.checkBoxErrorMessage}>
                  Please select at least one interest before submitting.
                </p>
              )}
            </div>
          </div>
          <button type="submit" className={styles.submitBtn + " button "}>
            {isLoading ? "Submitting..." : "SUBMIT"}
          </button>
        </form>
      </div>
    </div>
  );
}

export default Form;
